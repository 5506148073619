.footer {
    width: 100%;
    padding: 6rem 0;
    background: rgba(251, 251, 251, 0.8);
}

.footer-container {
    max-width: 1140px;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    color:#8d9c3d;
}

.footer .right {
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: auto;
    color:#8d9c3d;
    
}

.footer h4 {
    font-size: 1.4rem;
    padding: 1rem 0;
    color:#000;
}

.footer p {
    font-size: 1.2rem;
    padding:1rem 0;
    color:#000;
}

.footer .location {
    display: flex;
    align-items: center;
    color:#000;
}

.footer .location p {
    padding-bottom: .5rem;
    color:#000;
}


.footer .location h4 {
    padding-top: 0;
    color:#000;
}

@media screen and (max-width: 640px) {
    .footer-container {
        grid-template-columns: 1fr;
    }

    .right .social {
        margin: auto;
        color:#8d9c3d;
    }
    
}